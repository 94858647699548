import { getFeatureFlags } from '@smile/experimentation';
import type { ImpressionSequence } from '@thanks/impression-type';

import { analytics } from '../analytics';

export const configureAnalytics = ({
	impression,
	referral,
	viewMode,
	taint,
	theme,
}: {
	impression: ImpressionSequence;
	referral: string;
	viewMode: string;
	taint: string | undefined;
	theme: string;
}) => {
	const { hash, traceId, asOrganization, embedTheme, overrides, ...rest } =
		impression.analytics || {};

	analytics.identify(impression.impressionId, referral, {
		hash: hash,
		traceId: traceId,
		publisher: impression.partnerSiteId,
		publisherId: impression.partnerSiteId,
		...impression.analytics?.attributes?.client,
		referral: referral,
		environment: import.meta.env.ENVIRONMENT,
		release: import.meta.env.COMMIT_HASH,
		displayMode: viewMode,
		impressionRecord: impression,
		taint,
		theme,
		embedTheme,
		overrides: overrides?.join(',') || '',
		displayVariant: 3,
		attributes: {
			resolutionWidth: window.outerWidth,
			resolutionHeight: window.outerHeight,
			asOrganization: asOrganization || 'unset',
			experimentation: impression.experimentation,
			experiments: impression.experiment,
			featureFlags: getFeatureFlags() as any,
			...rest,
		},
	});
};
